import React from "react";
import { View, Text, useColorScheme } from "react-native";
import tailwind from "tailwind-rn";

const Count = ({ title, value, suffix }) => {
  const colorScheme = useColorScheme();

  return (
    <View style={tailwind("mr-4 my-1")}>
      <Text
        style={tailwind(
          `${
            colorScheme === "dark" ? "text-white" : "text-gray-700"
          } text-sm uppercase font-bold`
        )}
      >
        {title}
      </Text>
      <Text
        style={tailwind(
          `text-lg ${colorScheme === "dark" ? "text-white" : "text-gray-700"}`
        )}
      >
        {value}
        {suffix && `${suffix}`}
      </Text>
    </View>
  );
};

export default Count;
