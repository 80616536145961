import React, { useState, useEffect } from "react";
import {
  KeyboardAvoidingView,
  View,
  useColorScheme,
  useWindowDimensions,
  Platform,
} from "react-native";
import { SafeAreaView, SafeAreaProvider } from "react-native-safe-area-context";
import tailwind from "tailwind-rn";
import { useAsyncStorage } from "@react-native-async-storage/async-storage";
import { useDebouncedCallback } from "use-debounce";
import * as Clipboard from "expo-clipboard";
import { Helmet } from "react-helmet";
import Counts from "./src/components/Counts";
import Socials from "./src/components/Socials";
import TextArea from "./src/components/TextArea";
import Toolbar from "./src/components/Toolbar";

const App = () => {
  const colorScheme = useColorScheme();
  const { width } = useWindowDimensions();

  const [size, setSize] = useState("sm");
  const [text, setText] = useState("");
  const [keyboardActive, setKeyboardActive] = useState(false);

  const { getItem, setItem } = useAsyncStorage("@text");

  const readItemFromStorage = async () => {
    const item = await getItem();
    setText(item);
  };

  const writeItemToStorage = async (newValue) => {
    setText(newValue);
    debounced(newValue);
  };

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      setItem(value);
    },
    // delay in ms
    1000
  );

  useEffect(() => {
    readItemFromStorage();
  }, []);

  useEffect(() => {
    if (width >= 1200) {
      setSize("lg");
    } else if (width >= 768) {
      setSize("md");
    } else {
      setSize("sm");
    }
  }, [width]);

  const responsiveStyle = (styleArr) => {
    if (size === "lg") {
      return styleArr[2];
    }

    if (size === "md") {
      return styleArr[1];
    }

    return styleArr[0];
  };

  const clearText = () => {
    setText("");
    setItem("");
  };

  const copyText = () => {
    Clipboard.setString(text);
  };

  const pasteText = async () => {
    const clipboardText = await Clipboard.getStringAsync();
    setText(clipboardText);
    setItem(clipboardText);
  };

  const toggleKeyboard = () => setKeyboardActive(!keyboardActive);

  const wordCount = text
    ? text.split(" ").filter((word) => word !== "").length
    : 0;
  const charCount = text ? text.length : 0;
  const time = Math.round(wordCount / 200);

  return (
    <KeyboardAvoidingView
      behavior={"padding"} // you can change that by using Platform
      keyboardVerticalOffset={Platform.select({ ios: 0, android: -500 })}
      style={tailwind(
        `flex flex-1 ${colorScheme === "dark" ? "bg-gray-800" : "bg-gray-100"}`
      )}
    >
      {Platform.OS === "web" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Word Count Plus</title>
          <meta
            name="description"
            content="Word &amp; Character counter for Twitter, Facebook, Instagram &amp; LinkedIn"
          />
          <link rel="canonical" href="https://wordcount.plus" />
        </Helmet>
      )}
      <SafeAreaProvider>
        <SafeAreaView style={tailwind("flex flex-1")}>
          <View style={tailwind("flex flex-1 flex-col items-center")}>
            <View
              style={tailwind(
                "flex flex-row justify-between items-center px-4 py-2 w-full max-w-5xl"
              )}
            >
              <Counts wordCount={wordCount} charCount={charCount} time={time} />
              <Socials charCount={charCount} />
            </View>
            <TextArea
              text={text}
              setText={writeItemToStorage}
              setKeyboardActive={setKeyboardActive}
              keyboardActive={keyboardActive}
              responsiveStyle={responsiveStyle}
            />
            <Toolbar
              clearText={clearText}
              pasteText={pasteText}
              copyText={copyText}
              toggleKeyboard={toggleKeyboard}
              keyboardActive={keyboardActive}
            />
          </View>
        </SafeAreaView>
      </SafeAreaProvider>
    </KeyboardAvoidingView>
  );
};

export default App;
