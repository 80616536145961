import React from 'react';
import {View, useColorScheme} from 'react-native';
import tailwind from 'tailwind-rn';
import Count from './Count';

const Counts = ({wordCount, charCount, time}) => {
  const colorScheme = useColorScheme();

  return (
    <View
      // style={tailwind(
      //   `${
      //     colorScheme === 'dark' ? 'bg-gray-900' : 'bg-gray-200'
      //   } flex flex-row mx-6 my-3 py-1 px-4 justify-between rounded-xl`,
      // )}
      style={tailwind(`flex flex-row`)}>
      <Count title="Words" value={wordCount} />
      <Count title="Chars" value={charCount} />
      {/* <Count title="Time" value={time} suffix="m" /> */}
    </View>
  );
};

export default Counts;
