import React from "react";
import { View, useColorScheme } from "react-native";
import { ProgressCircle } from "react-native-svg-charts";
import tailwind from "tailwind-rn";
import Icon from "react-native-vector-icons/FontAwesome";

const Social = ({ brand, value, max }) => {
  const colorScheme = useColorScheme();

  const brandColour = () => {
    if (value > max) return "rgb(248, 113, 113)";
    if (brand === "facebook") return "rgb(59, 89, 152)";
    if (brand === "twitter") return "rgb(0, 172, 237)";
    if (brand === "instagram") return "rgb(81, 127, 164)";
    if (brand === "linkedin") return "rgb(0, 123, 182)";
    return "rgb(0,0,0)";
  };

  return (
    <View style={tailwind("relative h-11 w-11 ml-2 my-2")}>
      <ProgressCircle
        style={tailwind("h-11 w-11 absolute")}
        progress={value / max}
        progressColor={brandColour()}
        strokeWidth={value >= max ? 6 : 4}
        backgroundColor={colorScheme === "dark" ? "#111827" : "#ECECEC"}
      />
      {/* <Text style={tailwind('absolute')}>T</Text> */}
      <View style={tailwind("absolute inset-0 justify-center items-center")}>
        <Icon
          name={brand}
          size={18}
          color={colorScheme === "dark" ? "rgb(255, 255, 255)" : brandColour()}
        />
      </View>
    </View>
  );
};

export default Social;
