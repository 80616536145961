import React, { useEffect, useRef } from "react";
import { View, TextInput, useColorScheme, Keyboard } from "react-native";
import tailwind from "tailwind-rn";

const TextArea = ({
  text,
  setText,
  setKeyboardActive,
  keyboardActive,
  responsiveStyle,
}) => {
  const colorScheme = useColorScheme();

  const inputEl = useRef();

  useEffect(() => {
    if (keyboardActive === true) {
      inputEl.current.focus();
    } else {
      Keyboard.dismiss();
    }
  }, [keyboardActive]);

  return (
    <View style={tailwind("flex flex-1 w-full max-w-5xl")}>
      <TextInput
        ref={inputEl}
        value={text}
        onChangeText={setText}
        onFocus={() => setKeyboardActive(true)}
        style={tailwind(
          `flex flex-1 px-4 py-3 ${responsiveStyle([
            "text-base",
            "text-lg",
            "text-xl",
          ])} leading-6 border ${
            colorScheme === "dark"
              ? "bg-gray-700 text-white border-gray-900"
              : "bg-white text-gray-700 border-gray-200"
          }`
        )}
        placeholder="Enter text here"
        placeholderTextColor={colorScheme === "dark" ? "#e0e0e0" : "#757575"}
        multiline
        textAlignVertical="top"
        // returnKeyType="done"
        // onSubmitEditing={() => Keyboard.dismiss()}
      />
    </View>
  );
};

export default TextArea;
