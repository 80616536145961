import React from 'react';
import {View} from 'react-native';
import tailwind from 'tailwind-rn';
import Social from './Social';

const Socials = ({charCount}) => {
  return (
    <View style={tailwind('flex flex-row')}>
      <Social brand="twitter" value={charCount} max={240} />
      <Social brand="facebook" value={charCount} max={63206} />
      <Social brand="instagram" value={charCount} max={2200} />
      <Social brand="linkedin" value={charCount} max={1300} />
    </View>
  );
};

export default Socials;
