import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  useColorScheme,
  Platform,
} from "react-native";
import tailwind from "tailwind-rn";
import Icon from "react-native-vector-icons/FontAwesome";

const Toolbar = ({
  clearText,
  pasteText,
  copyText,
  toggleKeyboard,
  keyboardActive,
}) => {
  const colorScheme = useColorScheme();

  const getIconColor = () => {
    if (colorScheme === "dark") {
      if (keyboardActive) {
        return "text-blue-400";
      } else {
        return "text-white";
      }
    } else {
      if (keyboardActive) {
        return "text-blue-600";
      } else {
        return "text-gray-700";
      }
    }
  };

  return (
    <View
      style={tailwind(
        "flex flex-row justify-between items-center px-4 py-2 w-full max-w-5xl"
      )}
    >
      <TouchableOpacity onPress={clearText}>
        <Text
          style={tailwind(
            `${
              colorScheme === "dark" ? "text-red-400" : "text-red-700"
            } text-base`
          )}
        >
          Delete
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={copyText}>
        <Text
          style={tailwind(
            `${
              colorScheme === "dark" ? "text-white" : "text-gray-700"
            } text-base`
          )}
        >
          Copy
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={pasteText}>
        <Text
          style={tailwind(
            `${
              colorScheme === "dark" ? "text-white" : "text-gray-700"
            } text-base`
          )}
        >
          Paste
        </Text>
      </TouchableOpacity>
      {Platform.OS !== "web" && (
        <TouchableOpacity onPress={toggleKeyboard}>
          <Icon
            name="keyboard-o"
            size={18}
            style={tailwind(`${getIconColor()}`)}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default Toolbar;
